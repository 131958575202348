<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <b-col cols="12" class="pr-0 pl-0">
      <div class="form-group mb-0">
        <multiselect
          :value="form.fields.aparelho"
          id="aparelho"
          :disabled="!canEdit"
          :options="['Tonômetro de sopro', 'Aplanação', 'iCare', 'Tonopen']"
          :option-height="40"
          :showLabels="false"
          :searchable="false"
          :allowEmpty="false"
          placeholder="Aparelho de medição"
          class="with-border"
          @select="onSelect"
        >
          <template slot="caret">
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>
          <template slot="noOptions"> Nenhuma opção </template>
          <template slot="noResult"> Nenhum resultado </template>
        </multiselect>
      </div>
    </b-col>
    <b-row>
      <b-col cols="9" class="pr-0 pl-2 pt-3 pb-3">
        <table>
          <thead>
            <tr>
              <th>
                <Clock class="icon" />
              </th>
              <th>
                <EyeFill class="icon mr-2"/> D
              </th>
              <th>
                <EyeFill class="icon mr-2" /> E
              </th>
              <th class="pl-1" id="curva-tensional">
                <Graph 
                  class="ml-2 mt-2 cursor-pointer" 
                />
              </th>
              <b-popover  target="curva-tensional" triggers="hover" placement="top" >
                <b-row class="ml-3 mt-3">
                  <h5>Curva tensional</h5>
                </b-row>
                <b-row class="ml-2">
                  <b-col v-if="form?.fields?.aparelho">
                    <p>Aparelho de medição: {{ form?.fields?.aparelho }}</p>
                  </b-col>
                  <b-col >
                      <StraightChart /> Olho direito
                  </b-col>
                  <b-col>
                    <DashedChart /> Olho esquerdo
                  </b-col>
                </b-row>
                <v-line-chart 
                  :key="form.fields?.value?.length"
                  :data="getChart()"
                  :options="chartOptions"
                />
              </b-popover>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, index) in form.fields.value" :key="index">
              <b-input
                :value="value.hora"
                @input="value => updateFormValue('hora', value, index)"
                @blur="handleUpdateFormValue"
                v-mask="'##:##'"
                :readonly="!canEdit"
              ></b-input>
              <b-input
                class="no-border"
                type="number"
                :value="value.direito"
                @input="value => updateFormValue('direito', value, index)"
                @blur="handleUpdateFormValue"
                :readonly="!canEdit"
              ></b-input>
              <b-input
                :value="value.esquerdo"
                type="number"
                @input="value => updateFormValue('esquerdo', value, index)"
                @blur="handleUpdateFormValue"
                :readonly="!canEdit"
              ></b-input>
              <Trash 
                class="trash-icon mt-2 pl-2"
                :class="{'hide':index < 2 || !canEdit }"
                @click="handleRemoveValue(index)"
              />
            </tr>
          </tbody>
        </table>
      </b-col>
      <b-col v-show="canEdit" class="options-container ml-3" :class="{'pl-2':form.fields?.value.length > 2 }">
        <b-row>
          <Copy @click="copyValues" class="mr-2 cursor-pointer"
          v-if="canEdit"
            v-b-tooltip.hover title="Copiar valores O.D para O.E"/>
          <Add 
            id="add-button" 
            class="add icon cursor-pointer" 
            @click="addValue()"
          />
          <b-popover target="add-button" triggers="hover" placement="bottom">
            Adicionar novo campo para medição
          </b-popover>
        </b-row>
      </b-col>
    </b-row>
    <b-col class="pr-0 pl-0">
      <v-text-area
        id="laudo"
        rows="1"
        autocomplete="off"
        :value="form.fields.laudo"
        :readonly="!canEdit"
        @input="value => updateForm('laudo', value)"
        @blur="updateMedicalRecord(form)"
        placeholder="Laudar exame"
      />
    </b-col>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import FormWrapper from '@/components/Attendance/Forms/FormWrapper'

import TextArea from '@/components/General/TextArea'

import ChevronDown from '@/assets/icons/chevron-down.svg'
import EyeFill from '@/assets/icons/eye-fill.svg'
import Clock from '@/assets/icons/clock.svg'
import Copy from '@/assets/icons/copy.svg'
import Add from '@/assets/icons/plus.svg'
import Trash from '@/assets/icons/trash.svg'
import Graph from '@/assets/icons/graph.svg'
import LineChart from '@/components/LineChart'
import DashedChart from '@/assets/icons/dashed-chart-line.svg'
import StraightChart from '@/assets/icons/straight-chart-line.svg'

export default {
  components: {
    FormWrapper, 
    ChevronDown, 
    Clock, 
    EyeFill,
    Copy,
    Add,
    Trash,
    Graph, StraightChart, DashedChart,
    'v-text-area': TextArea,
    'v-line-chart': LineChart,
  },
  data () {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        scales: {
          yAxes: [
            {
              gridLines: {
                color: '#D9DFF2'
              },
              ticks: {
                stepSize: 1,
                fontSize: 10,
                fontColor: '#8696AC'
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                borderDash: [10, 2],
                color: '#D9DFF2'
              },
              ticks: {
                fontSize: 10,
                fontColor: '#8696AC'
              }
            }
          ]
        },
        tooltips: {
          mode: 'label',
          bodyAlign: 'center'
        }
      },
    }
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.curvaTensional,
    }),
  },
  watch: {
    'form.fields.value': {
      handler (value) {
        if(value?.length < 2){
          this.getDefaultValueFields()
        }
      }, 
      deep: true
    }
  },
  methods: {
    ...mapActions('attendance/form', ['handleActiveModule', 'updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/curvaTensional', [
      'handleFields', 
      'handleProps', 
      'handleFieldValue', 
      'addValue', 
      'getDefaultValueFields',
      'removeValue',
    ]),

    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    updateFormValue(key, value, index) {
      this.handleFieldValue({ key, value, index })
    },
    onSelect(value) {
      this.updateForm('aparelho', value)
      this.updateMedicalRecord(this.form)
    },
    handleUpdateFormValue () {
      if(!this.form?.fields?.value?.length) return;

      this.updateMedicalRecord(this.form)
    },
    handleRemoveValue (index) {
      this.removeValue(index)
      this.updateMedicalRecord(this.form)
    },
    copyValues () {
      this.form.fields.value.map(value => {
        value.esquerdo = value.direito
      })
    },

    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.fields.aparelho = null
      this.form.fields.laudo = null
      this.form.fields.value.splice(0,2)
      console.log(this.form)
      this.form.fields.value.forEach(value => {
        value.hora = null
        value.direito = null
        value.esquerdo = null
      });

    },

    getChart () {
      const horas = this.form?.fields?.value?.map(el => el.hora)
      const esquerdo = this.form?.fields?.value?.map(el => el.esquerdo)
      const direito = this.form?.fields?.value?.map(el => el.direito)

      return  {
        labels: [...new Set(horas.filter(n => n))],
        datasets: [
          {
            label: 'Olho direito',
            borderColor: 'rgba(48, 91, 242, 1)',
            backgroundColor: 'rgba(48, 91, 242, 0.1)',
            pointBackgroundColor: 'rgba(48, 91, 242, 1)',
            pointRadius: 4,
            pointHoverBackgroundColor: '#FFFFFF',
            pointHoverBorderWidth: 2,
            lineTension: 0.3,
            data: direito.filter(n => n)
          },
          {
            label: 'Olho esquerdo',
            borderColor: '#FF6B00',
            backgroundColor: '#FF6B00',
            pointBackgroundColor: '#FF6B00',
            pointRadius: 4,
            pointHoverBackgroundColor: '#FFFFFF',
            pointHoverBorderWidth: 2,
            lineTension: 0.3,
            data: esquerdo.filter(n => n)
          }
        ]
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.icon {
  width: 18px;
  height: 18px;
}

table {
  width: 100%;
  thead {
    tr {
      display: flex;
      flex-direction: row;
    }
    tr th:nth-child(-n + 3) {
      width: 100%;
      height: 35px;
      display: flex;
      background: var(--neutral-200);
      border: 1px solid var(--neutral-300);
      border-bottom: none !important;
      flex-direction: row;
      justify-content: center;
      vertical-align: middle;
      align-items: center;
    }

    th:nth-child(1) {
      border-top-left-radius: 8px;
    }

    th:nth-child(3) {
      border-top-right-radius: 8px;
    }
  }

  tbody {
    tr {
      width: 100%;
      display: flex;
      
      .form-control {  
        border-radius: 0px !important;
      }
      .no-border {
        border-radius: 0px !important;
      }
    }

    tr:last-child {
      input:nth-child(1) {
        border-radius: 0px 0px 0px 8px !important;
      }

      input:nth-child(3) {
        border-radius: 0px 0px 8px 0px !important;
      }
    }
  }
}

h5 {
  color: var(--dark-blue);
  font-weight: 700;
  font-size: 18px;
}
.options-container {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 29px;
  .add {
    fill: var(--blue-500);
    width: 24px;
    height: 24px;
  }
}
.trash-icon {
  cursor: pointer;
  display: flex;
  justify-content: end;
  flex-direction: row;
  width: 90px;
  height: 30px;
  stroke: var(--states-red-soft);
}

.hide {
  visibility: hidden;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
